import * as React from "react";
import { useState } from "react";
import { styled, useTheme } from "@mui/material/styles";
import { Link as RouterLink, useNavigate } from "react-router-dom";
import { setWallet } from "../../../redux/auth/actions";
import { ethers, utils } from "ethers";

import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Badge from "@mui/material/Badge";
import MenuItem from "@mui/material/MenuItem";
import Menu from "@mui/material/Menu";
import MailIcon from "@mui/icons-material/Mail";
import NotificationsIcon from "@mui/icons-material/Notifications";
import userHeader from "assets/images/userHeader.png";
import personuser from "assets/images/person_user.png";
import galileoLogo from "assets/images/profile/logoG.svg";
import changePasswordIcon from "assets/images/icons/change-password.svg";
import Drawer from "../drawer/drawer";
import { logout } from "redux/auth/actions";
import NFTDropdown from "./countriesDropdown";
import { useSelector, useDispatch } from "react-redux";
import { Button, ListItemIcon, Tooltip } from "@mui/material";
import { Helmet } from "react-helmet";
// import MetaMask from 'shared/metaMaskwithBalance';
import { useEffect } from "react";
import { styled as stylee } from "@mui/system";
import { MENU_OPEN, SNACKBAR_OPEN } from "store/actions";
import Erc20 from "../../../contractAbi/Erc20.json";
import BLOCKCHAIN from "../../../../src/constants";
import { withStyles } from "@material-ui/core/styles";
import { toast } from "react-toastify";
import { Icons } from "../../../shared/Icons/Icons";
import NotificationMenu from "./NotificationMenu";
import SearchField from "./SearchField";
import { getAllCartItems, notificationCountIcon } from "redux/marketplace/actions";
import { WRONG_CHAIN_MESSAGES, CHAIN_IDS } from "utils/constants";

const CustomTypography = withStyles((theme) => ({
  tooltip: {
    backgroundColor: "#44494c",
    color: "white"
  }
}))(Tooltip);

const SquareIconButton = stylee(IconButton)(({ theme }) => ({
  width: "38px",
  height: "38px",
  borderRadius: "8px",
  background: theme.palette.action.hover
}));

export default function Header() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  // getAllCartItems
  const handleLogout = () => {
    dispatch(logout());
    localStorage.removeItem("userClickedLater");
    navigate("/login");
  };
  const token = useSelector((state) => state.auth?.token);
  const user = useSelector((state) => state.auth?.user);
  const userWalletAddress = useSelector((state) => state.auth?.walletAddress);
  const cartItems = useSelector((state) => state.marketplaceReducer.cartItems);

  const [anchorEl, setAnchorEl] = useState(null);
  const [groupedCartItems, setGroupedCartItems] = useState([]);

  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = useState(null);

  const [walletAddress, setWalletAddress] = useState();
  const [balanceValue, setbalanceValue] = useState();
  const [maticValue, setMaticValue] = useState();

  const [notificationDrop, setNotificationDrop] = useState(null);
  const isNotificationOpen = Boolean(notificationDrop);
  const notificationsIconss = useSelector((state) => state.marketplaceReducer.notificationIconCount);

  const isMenuOpen = Boolean(anchorEl);
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);

  useEffect(() => {
    (async () => {
      const provider = new ethers.providers.Web3Provider(window.ethereum);
      const signer = provider.getSigner();
      const address = await signer.getAddress();
      const chainId = await provider.getNetwork();

      // Check if user is logged in
      // Metamask is connected to browser
      // Connected network is Mumbai
      if (token && address && chainId?.chainId?.toString() === "80002") {
        handleConnect();
      }
    })();
  }, [token]);

  const handleConnect = async () => {
    const response = await window?.ethereum?.request({ method: "eth_requestAccounts" });
    if (response) {
      if (!window.ethereum) {
        dispatch({
          type: SNACKBAR_OPEN,
          open: true,
          message: "You need to install metamask to proceed.",
          variant: "alert",
          alertSeverity: "info"
        });
        toast.error("You need to install metamask to proceed.");
      } else if (window?.ethereum?.networkVersion !== CHAIN_IDS.POLYGON_CHAIN_ID) {
        // dispatch({
        //   type: SNACKBAR_OPEN,
        //   open: true,
        //   message: " Please switch to polygon network from your metamask",
        //   variant: "alert",
        //   alertSeverity: "info"
        // });
        toast.error(WRONG_CHAIN_MESSAGES.POLYGON);
        setWalletAddress();
        dispatch(setWallet(null));
      } else {
        const addressed = utils?.getAddress(response[0]);
        setWalletAddress(addressed);
        dispatch(setWallet(addressed));

        const maticprovider = ethers.getDefaultProvider(
          "https://polygon-amoy.g.alchemy.com/v2/fKkGRNI1lDjgZu8WMIIjK_VpqxeOOE51"
        );
        const maticbalance = await maticprovider?.getBalance(response[0]);
        const maticvalue = ethers.utils?.formatEther(maticbalance);
        setMaticValue(Number(maticvalue));
        const provider = new ethers.providers.Web3Provider(window.ethereum);
        const signer = provider.getSigner();
        const address = await signer.getAddress();
        let erc20Address = BLOCKCHAIN.USDT_ERC20;

        const token = new ethers.Contract(erc20Address, Erc20.abi, signer);

        let balance = await token.balanceOf(address);
        let value = utils.formatUnits(balance, 6);
        setbalanceValue(Number(value));
        // dispatch({
        //     type: SNACKBAR_OPEN,
        //     open: true,
        //     message: 'Success',
        //     variant: 'alert',
        //     alertSeverity: 'success'
        // });
      }
    } else {
      toast.error("You need to install metamask to proceed.");
    }
  };
  const handleProfileMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    handleMobileMenuClose();
  };

  const handleMobileMenuOpen = (event) => {
    setMobileMoreAnchorEl(event.currentTarget);
  };
  const StyledBadge = styled(Badge)(({ theme }) => ({
    "& .MuiBadge-badge": {
      backgroundColor: "black",
      color: "white",
      right: 5,
      top: 7,
      padding: "0 4px",
      height: 17,
      minWidth: 17,
      fontSize: 11,
      fontWeight: "bold",
      border: `2px solid ${theme.palette.mode === "dark" ? "#2A2E31" : "white"}`
    }
  }));
  const menuId = "primary-search-account-menu";

  const renderMenu = (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "right"
      }}
      id={menuId}
      sx={{ top: "2.2%" }}
      keepMounted
      transformOrigin={{
        vertical: "top",
        horizontal: "right"
      }}
      open={isMenuOpen}
      onClose={handleMenuClose}
    >
      <MenuItem component={RouterLink} className="userName">
        {user?.firstName} {user?.lastName}
      </MenuItem>
      <MenuItem component={RouterLink} to="/creatorProfile" onClick={handleMenuClose}>
        <ListItemIcon>{Icons.profile}</ListItemIcon>
        My Profile
      </MenuItem>
      <MenuItem component={RouterLink} to="/editProfile" onClick={handleMenuClose}>
        <ListItemIcon>{Icons.edit}</ListItemIcon>
        Edit Profile
      </MenuItem>

      {user && user.signupMethod === "DIRECT" && (
        <MenuItem component={RouterLink} to="/changePassword" onClick={handleMenuClose}>
          <ListItemIcon>
            <img src={changePasswordIcon} alt="" style={{ paddingLeft: "2px" }} />
          </ListItemIcon>
          Change Password
        </MenuItem>
      )}

      {/* <MenuItem component={RouterLink} to="/deliveryDashboard" onClick={handleMenuClose}>
          <ListItemIcon>
            {Icons.delivery}
          </ListItemIcon>
          Delivery Dashboard
        </MenuItem>
      
      <MenuItem onClick={handleLogout}>
        <ListItemIcon>{Icons.logoutNew}</ListItemIcon>
        Logout
      </MenuItem> */}
    </Menu>
  );

  const mobileMenuId = "primary-search-account-menu-mobile";
  const renderMobileMenu = (
    <Menu
      anchorEl={mobileMoreAnchorEl}
      anchorOrigin={{
        vertical: "top",
        horizontal: "right"
      }}
      id={mobileMenuId}
      keepMounted
      transformOrigin={{
        vertical: "top",
        horizontal: "right"
      }}
      open={isMobileMenuOpen}
      onClose={handleMobileMenuClose}
    >
      <MenuItem>
        <IconButton size="large" aria-label="show 4 new mails" color="inherit">
          <Badge badgeContent={4} color="error">
            <MailIcon />
          </Badge>
        </IconButton>
        <p>Messages</p>
      </MenuItem>
      <MenuItem>
        <IconButton
          size="large"
          aria-label="show 17 
                new notifications"
          color="inherit"
        >
          <Badge badgeContent={17} color="error">
            <NotificationsIcon />
          </Badge>
        </IconButton>
        <p>Notifications</p>
      </MenuItem>

      <MenuItem onClick={handleProfileMenuOpen}>
        <IconButton
          size="large"
          aria-label="account of current user"
          aria-controls="primary-search-account-menu"
          aria-haspopup="true"
          color="inherit"
        ></IconButton>
        <p>Profile</p>
      </MenuItem>
    </Menu>
  );

  useEffect(() => {
    const handleAccountsChanged = () => {
      handleConnect();
    };

    if (window.ethereum) {
      window.ethereum.on("accountsChanged", handleAccountsChanged);
    }

    // Cleanup function to remove the event listener when component unmounts
    return () => {
      if (window.ethereum) {
        window.ethereum.off("accountsChanged", handleAccountsChanged);
      }
    };
  }, []);

  useEffect(() => {
    if (token && userWalletAddress) {
      handleConnect();
    }
  }, [token, userWalletAddress]);

  useEffect(() => {
    dispatch(getAllCartItems());
  }, []);

  useEffect(() => {
    // Create an object to store grouped cart items
    const updatedGroupedCartItems = {};

    // Iterate through the cartItems
    cartItems.forEach((item) => {
      const bulkId = item.Nft.bulkId;

      // If the bulkId is null or undefined, use a unique key to group such items
      const groupKey = bulkId || `uniqueKey_${item.id}`;

      // If the groupKey is not in the updatedGroupedCartItems, add it with the item
      if (!updatedGroupedCartItems[groupKey]) {
        // Clone the item and Nft objects
        updatedGroupedCartItems[groupKey] = {
          ...item,
          Nft: { ...item.Nft }
        };
      } else {
        // If the groupKey is already in the updatedGroupedCartItems, update the selectedQuantity
        updatedGroupedCartItems[groupKey].selectedQuantity += item.selectedQuantity;

        // Update the quantity with the biggest value
        updatedGroupedCartItems[groupKey].quantity = Math.max(
          updatedGroupedCartItems[groupKey].quantity,
          item.quantity
        );

        // Update the errorMessage property from the Nft object if it exists
        if (item.Nft.errorMessage) {
          updatedGroupedCartItems[groupKey].Nft.errorMessage = item.Nft.errorMessage;
        }
      }
    });

    // Convert the updatedGroupedCartItems object back to an array if needed
    const updatedGroupedCartItemsArray = Object.values(updatedGroupedCartItems);

    // Update the state with the grouped cart items
    setGroupedCartItems(updatedGroupedCartItemsArray);
  }, [cartItems]);
  const theme = useTheme();

  return (
    <>
      {/* <MetaMask open={metamask} setOpen={setMetamask} /> */}
      <Box sx={{ flexGrow: 1 }}>
        <AppBar
          position="static"
          sx={{ backgroundColor: `${theme.palette.mode === "dark" ? "#181C1F" : "white"}`, borderRadius: "4px" }}
        >
          <Toolbar>
            <Helmet>
              <meta charSet="utf-8" />
              <title>Galileo Marketplace</title>
              <link rel="canonical" href="http://mysite.com/example" />
            </Helmet>
            <Box
              sx={{
                height: "3em",
                // paddingTop: '1em',
                width: "100%",
                marginLeft: "1%",
                display: "flex"
                // [theme.breakpoints.down('md')]: {
                //     width: 'auto'
                // }
              }}
            >
              <Grid container-fluid="true" sx={{ display: "flex", marginTop: "3px", alignItems: "center" }}>
                <Grid item sx={{ display: { lg: "none", md: "none" } }}>
                  <Drawer />
                </Grid>
                <Grid item md={2}>
                  {theme.palette.mode === "dark" ? (
                    <img
                      src={galileoLogo}
                      onClick={() => {
                        navigate("/home");
                      }}
                      alt="Galileo White Logo"
                      width="100"
                      style={{ cursor: "pointer" }}
                    />
                  ) : (
                    <img
                      src={galileoLogo}
                      onClick={() => {
                        navigate("/home");
                      }}
                      alt="Galileo Dark Logo"
                      width="100"
                      style={{ cursor: "pointer" }}
                    />
                  )}
                </Grid>
              </Grid>
              <Box sx={{ flexGrow: 1, paddingLeft: 6 }}>
                <SearchField />
              </Box>
              <Box sx={{ paddingLeft: 6 }}>
                <NFTDropdown />
              </Box>

              <Box sx={{ flexGrow: 1 }} />
              {(user?.role == "Sub Admin" || "Brand Admin" || "Super Admin") &&
                user?.role != "User" &&
                (token !== null || undefined) &&
                !/metamaskmobile/.test(window.navigator.userAgent.toLowerCase()) && (
                  <Box sx={{ marginTop: "3px" }}>
                    <SquareIconButton
                      onClick={() => {
                        if (user?.role === "Sub Admin") {
                          let url = `/nftManagement/${user?.CategoryId}/${user?.BrandId}?pageNumber=1&filter=draft`;
                          dispatch({ type: MENU_OPEN, id: "brand" });
                          navigate(url);
                        } else {
                          dispatch({ type: MENU_OPEN, id: "admin" });
                          navigate("/subAdminManagement");
                        }
                      }}
                      size="large"
                      aria-label=""
                      color="inherit"
                      sx={{ mx: 1 }}
                    >
                      <Badge>{Icons.dashboard}</Badge>
                    </SquareIconButton>
                  </Box>
                )}
              {(user?.role == "Sub Admin" || "Brand Admin" || "Super Admin" || "User") &&
                (token != null || undefined) && (
                  <>
                    <Box sx={{ display: { xs: "none", sm: "flex", md: "flex" } }}>
                      {user?.walletAddress && walletAddress ? (
                        <Box
                          sx={{
                            background: theme.palette.action.hover,
                            borderRadius: "8px",
                            display: "flex",
                            alignItems: "center",
                            px: 2,
                            mr: 0.5,
                            color: `${theme.palette.mode === "dark" ? "#ffffff" : "#000000"}`
                          }}
                        >
                          {walletAddress !== user.walletAddress ? (
                            <CustomTypography title="This wallet is not registered" placement="top" arrow>
                              <Box sx={{ display: "flex", position: "relative" }}>
                                {Icons.wallet}
                                <Box sx={{ position: "absolute", right: "2%", top: "-4%" }}>{Icons.evenodd}</Box>
                              </Box>
                            </CustomTypography>
                          ) : (
                            Icons.wallet
                          )}

                          <Typography sx={{ ml: 1 }}>
                            {balanceValue && (
                              <>
                                {balanceValue.toFixed(2)} <Box as="span">USDT</Box>
                              </>
                            )}
                          </Typography>
                          <Box sx={{ borderRight: "1px solid  #575c5f", height: "50%", marginX: "5px" }}></Box>
                          <Typography>
                            {/* {balanceValue && balanceValue.slice(0, 5)} <Box as="span">USDT</Box> */}
                            {maticValue && maticValue.toFixed(2)} <Box as="span">MATIC</Box>
                          </Typography>
                          <Box sx={{ borderRight: "1px solid  #575c5f", height: "50%", marginX: "5px" }}></Box>
                          <Typography>
                            {walletAddress && walletAddress.slice(0, 5) + "..." + walletAddress.slice(39, 42)}
                          </Typography>
                          <Box sx={{ borderRight: "2px solid #575c5f", mx: 1, height: "100%" }}></Box>
                          <Box>
                            {token && user?.role == "Super Admin" && user?.isVerified == true && (
                              <>
                                {theme.palette.mode === "dark" ? (
                                  <img
                                    src={user?.UserProfile?.profileImg || userHeader}
                                    alt=""
                                    height="25"
                                    width="25"
                                    style={{
                                      display: "inlineBlock",
                                      marginTop: "3px",
                                      borderRadius: "50%",
                                      border: "2px solid white",
                                      objectFit: "cover"
                                    }}
                                    onClick={handleProfileMenuOpen}
                                  />
                                ) : (
                                  <img
                                    src={user?.UserProfile?.profileImg || personuser}
                                    alt=""
                                    height="25"
                                    width="25"
                                    style={{
                                      display: "inlineBlock",
                                      marginTop: "3px",
                                      borderRadius: "50%",
                                      border: "2px solid black",
                                      objectFit: "cover"
                                    }}
                                    onClick={handleProfileMenuOpen}
                                  />
                                )}
                              </>
                            )}
                            {token && user?.role == "Sub Admin" && user?.isVerified == true && (
                              <>
                                {theme.palette.mode === "dark" ? (
                                  <img
                                    src={user?.UserProfile?.profileImg || userHeader}
                                    alt=""
                                    height="25"
                                    width="25"
                                    style={{
                                      display: "inlineBlock",
                                      marginTop: "3px",
                                      borderRadius: "50%",
                                      border: "2px solid white",
                                      objectFit: "cover"
                                    }}
                                    onClick={handleProfileMenuOpen}
                                  />
                                ) : (
                                  <img
                                    src={user?.UserProfile?.profileImg || personuser}
                                    alt=""
                                    height="25"
                                    width="25"
                                    style={{
                                      display: "inlineBlock",
                                      marginTop: "3px",
                                      borderRadius: "50%",
                                      border: "2px solid black",
                                      objectFit: "cover"
                                    }}
                                    onClick={handleProfileMenuOpen}
                                  />
                                )}
                              </>
                            )}
                            {token && user?.role == "Brand Admin" && user?.isVerified == true && (
                              <>
                                {theme.palette.mode === "dark" ? (
                                  <img
                                    src={user?.UserProfile?.profileImg || userHeader}
                                    alt=""
                                    height="25"
                                    width="25"
                                    style={{
                                      display: "inlineBlock",
                                      marginTop: "3px",
                                      borderRadius: "50%",
                                      border: "2px solid white",
                                      objectFit: "cover"
                                    }}
                                    onClick={handleProfileMenuOpen}
                                  />
                                ) : (
                                  <img
                                    src={user?.UserProfile?.profileImg || personuser}
                                    alt=""
                                    height="25"
                                    width="25"
                                    style={{
                                      display: "inlineBlock",
                                      marginTop: "3px",
                                      borderRadius: "50%",
                                      border: "2px solid black",
                                      objectFit: "cover"
                                    }}
                                    onClick={handleProfileMenuOpen}
                                  />
                                )}
                              </>
                            )}

                            {user?.role == "User" && user?.isVerified == false && (
                              <Button
                                size="large"
                                variant="outlined"
                                onClick={() => {
                                  navigate("/login");
                                }}
                              >
                                Login
                              </Button>
                            )}

                            {token && user?.role == "User" && user?.isVerified == true && (
                              <>
                                {theme.palette.mode === "dark" ? (
                                  <img
                                    src={user?.UserProfile?.profileImg || userHeader}
                                    alt=""
                                    height="25"
                                    width="25"
                                    style={{
                                      display: "inlineBlock",
                                      marginTop: "3px",
                                      borderRadius: "50%",
                                      border: "2px solid white",
                                      objectFit: "cover"
                                    }}
                                    onClick={handleProfileMenuOpen}
                                  />
                                ) : (
                                  <img
                                    src={user?.UserProfile?.profileImg || personuser}
                                    alt=""
                                    height="25"
                                    width="25"
                                    style={{
                                      display: "inlineBlock",
                                      marginTop: "3px",
                                      borderRadius: "50%",
                                      border: "2px solid black",
                                      objectFit: "cover"
                                    }}
                                    onClick={handleProfileMenuOpen}
                                  />
                                )}
                              </>
                            )}
                          </Box>
                        </Box>
                      ) : (
                        <Box
                          sx={{
                            background: theme.palette.action.hover,
                            borderRadius: "8px",
                            display: "flex",
                            alignItems: "center",
                            px: 2,
                            mr: 1,
                            color: `${theme.palette.mode === "dark" ? "#ffffff" : "#000000"}`
                          }}
                        >
                          {Icons.wallet}
                          <Typography onClick={handleConnect} sx={{ px: 1, cursor: "pointer" }}>
                            Connect Wallet
                          </Typography>
                          <Box sx={{ borderRight: "2px solid #575c5f", mx: 1, height: "100%" }}></Box>

                          <Box>
                            {token && user?.role == "Super Admin" && user?.isVerified == true && (
                              <>
                                {theme.palette.mode === "dark" ? (
                                  <img
                                    src={user?.UserProfile?.profileImg || userHeader}
                                    alt=""
                                    height="25"
                                    width="25"
                                    style={{
                                      display: "inlineBlock",
                                      marginTop: "3px",
                                      borderRadius: "50%",
                                      border: "2px solid white",
                                      objectFit: "cover"
                                    }}
                                    onClick={handleProfileMenuOpen}
                                  />
                                ) : (
                                  <img
                                    src={user?.UserProfile?.profileImg || personuser}
                                    alt=""
                                    height="25"
                                    width="25"
                                    style={{
                                      display: "inlineBlock",
                                      marginTop: "3px",
                                      borderRadius: "50%",
                                      border: "2px solid black",
                                      objectFit: "cover"
                                    }}
                                    onClick={handleProfileMenuOpen}
                                  />
                                )}
                              </>
                            )}
                            {token && user?.role == "Sub Admin" && user?.isVerified == true && (
                              <>
                                {theme.palette.mode === "dark" ? (
                                  <img
                                    src={user?.UserProfile?.profileImg || userHeader}
                                    alt=""
                                    height="25"
                                    width="25"
                                    style={{
                                      display: "inlineBlock",
                                      marginTop: "3px",
                                      borderRadius: "50%",
                                      border: "2px solid white",
                                      objectFit: "cover"
                                    }}
                                    onClick={handleProfileMenuOpen}
                                  />
                                ) : (
                                  <img
                                    src={user?.UserProfile?.profileImg || personuser}
                                    alt=""
                                    height="25"
                                    width="25"
                                    style={{
                                      display: "inlineBlock",
                                      marginTop: "3px",
                                      borderRadius: "50%",
                                      border: "2px solid black",
                                      objectFit: "cover"
                                    }}
                                    onClick={handleProfileMenuOpen}
                                  />
                                )}
                              </>
                            )}
                            {token && user?.role == "Brand Admin" && user?.isVerified == true && (
                              <>
                                {theme.palette.mode === "dark" ? (
                                  <img
                                    src={user?.UserProfile?.profileImg || userHeader}
                                    alt=""
                                    height="25"
                                    width="25"
                                    style={{
                                      display: "inlineBlock",
                                      marginTop: "3px",
                                      borderRadius: "50%",
                                      border: "2px solid white",
                                      objectFit: "cover"
                                    }}
                                    onClick={handleProfileMenuOpen}
                                  />
                                ) : (
                                  <img
                                    src={user?.UserProfile?.profileImg || personuser}
                                    alt=""
                                    height="25"
                                    width="25"
                                    style={{
                                      display: "inlineBlock",
                                      marginTop: "3px",
                                      borderRadius: "50%",
                                      border: "2px solid black",
                                      objectFit: "cover"
                                    }}
                                    onClick={handleProfileMenuOpen}
                                  />
                                )}
                              </>
                            )}
                            <>
                              {user?.role == "User" && user?.isVerified == false && (
                                <Button
                                  size="large"
                                  variant="outlined"
                                  onClick={() => {
                                    navigate("/login");
                                  }}
                                >
                                  Login
                                </Button>
                              )}
                            </>
                            {token && user?.role == "User" && user?.isVerified == true && (
                              <>
                                {theme.palette.mode === "dark" ? (
                                  <img
                                    src={user?.UserProfile?.profileImg || userHeader}
                                    alt=""
                                    height="25"
                                    width="25"
                                    style={{
                                      display: "inlineBlock",
                                      marginTop: "3px",
                                      borderRadius: "50%",
                                      border: "2px solid white",
                                      objectFit: "cover"
                                    }}
                                    onClick={handleProfileMenuOpen}
                                  />
                                ) : (
                                  <img
                                    src={user?.UserProfile?.profileImg || personuser}
                                    alt=""
                                    height="25"
                                    width="25"
                                    style={{
                                      display: "inlineBlock",
                                      marginTop: "3px",
                                      borderRadius: "50%",
                                      border: "2px solid black",
                                      objectFit: "cover"
                                    }}
                                    onClick={handleProfileMenuOpen}
                                  />
                                )}
                              </>
                            )}
                          </Box>
                        </Box>
                      )}
                    </Box>
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                      <SquareIconButton
                        size="large"
                        aria-label="notification"
                        color="inherit"
                        sx={{ mx: 0.5 }}
                        onClick={(e) => {
                          setNotificationDrop(e.currentTarget);
                          if (notificationsIconss) {
                            dispatch(notificationCountIcon(false));
                          }
                        }}
                      >
                        <Badge>{notificationsIconss ? Icons.notification : Icons.notificationCount}</Badge>
                      </SquareIconButton>

                      {user?.role === "User" && process.env.REACT_APP_ENVIRONMENT === "development" && (
                        <Box className="cartIcon">
                          <SquareIconButton
                            size="large"
                            aria-label="cart"
                            color="inherit"
                            title="Cart"
                            sx={{ mx: 0.5 }}
                            onClick={() => {
                              navigate("/cart");
                            }}
                          >
                            <StyledBadge badgeContent={groupedCartItems?.length}>{Icons.shoppingCart}</StyledBadge>
                          </SquareIconButton>
                        </Box>
                      )}
                    </Box>
                  </>
                )}

              {/*   ******************* these are important condtions */}
              {(token == null || undefined) && (
                <Button size="large" variant="outlined" onClick={handleLogout} sx={{ ml: 1 }}>
                  Login
                </Button>
              )}
            </Box>
          </Toolbar>
        </AppBar>
        {(user?.role == "Sub Admin" || "Brand Admin" || "Super Admin" || "User") && (token != null || undefined) && (
          <>
            {renderMobileMenu}
            {renderMenu}

            <NotificationMenu
              notificationDrop={notificationDrop}
              setNotificationDrop={setNotificationDrop}
              isNotificationOpen={isNotificationOpen}
            />
          </>
        )}
      </Box>
    </>
  );
}
