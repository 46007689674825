export const CHAIN_IDS = {
  POLYGON_CHAIN_ID: process.env.REACT_APP_MAINNET == 1 ? "137" : "80002",
  ETHEREUM_CHAIN_ID: process.env.REACT_APP_MAINNET == 1 ? "1" : "11155111"
};
export const WRONG_CHAIN_MESSAGES = {
  POLYGON:
    process.env.REACT_APP_MAINNET == 1
      ? "Please switch to polygon network from your metamask"
      : "Please switch to testnet polygon amoy network from your metamask",
  ETHEREUM:
    process.env.REACT_APP_MAINNET == 1
      ? "Please switch to etherium network from your metamask"
      : "Please switch to testnet etherium network from your metamask"
};
export const RPC_URLS = {
  POLYGON_RPC_URL:
    process.env.REACT_APP_MAINNET == 1
      ? "https://polygon-mainnet.g.alchemy.com/v2/1MrZ8jU6_sCmPoyaceP73sT2qJeyo_Vf"
      : "https://polygon-amoy.g.alchemy.com/v2/fKkGRNI1lDjgZu8WMIIjK_VpqxeOOE51",
  ETHEREUM_RPC_URL:
    process.env.REACT_APP_MAINNET == 1
      ? "https://eth-mainnet.g.alchemy.com/v2/ycH7fGS1FUXKsIgGo7DyKwWuXde455pv"
      : "https://eth-sepolia.g.alchemy.com/v2/gW0p-Ys1dEZ8PrJlIEwA38bh0eijNinM"
};
export const BLOCK_EXPLORER_URL =
  process.env.MAINNET == 1 ? "https://polygonscan.com/" : "https://amoy.polygonscan.com/";
