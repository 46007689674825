import { useRoutes } from "react-router-dom";
import { useSelector } from "react-redux";
// routes
import SuperAdminRoutes from "./SuperAdminRoutes";
import SubAdminRoutes from "./SubAdminRoutes";
import BrandAdminRoutes from "./BrandAdminRoutes";
import UserRoutes from "./UserRoutes";
import LoginRoutes from "./LoginRoutes";
import TrackingRoutes from "./TrackingRoute";
import ProfileRoutes from "./ProfileRoutes";

export default function ThemeRoutes() {
  const userData = useSelector((state) => state.auth);

  if (userData.user && userData.user.role == "Super Admin") {
    return useRoutes([UserRoutes, SuperAdminRoutes, TrackingRoutes, ProfileRoutes]);
  } else if (userData.user && userData.user.role == "Sub Admin") {
    return useRoutes([UserRoutes, SubAdminRoutes, TrackingRoutes, ProfileRoutes]);
  } else if (userData.user && userData.user.role == "Brand Admin") {
    return useRoutes([UserRoutes, BrandAdminRoutes, TrackingRoutes, ProfileRoutes]);
  } else if (userData.user && userData.user.role == "User") {
    return useRoutes([UserRoutes, LoginRoutes, TrackingRoutes, ProfileRoutes]);
  } else {
    return useRoutes([UserRoutes, LoginRoutes, TrackingRoutes]);
  }
}
