export function getAddressValuesString(addressObj) {
  // return `${addressObj?.houseNo || ''}, ${addressObj?.area || ''},  ${addressObj?.landmark || ''},  ${addressObj?.city || ''},  ${addressObj?.state || ''},  ${addressObj?.pinCode || ''},  ${addressObj?.country || ''}`;
  return (
    `${addressObj?.houseNo ? addressObj.houseNo + ", " : ""}` +
    `${addressObj?.area ? addressObj.area + ", " : ""}` +
    `${addressObj?.landmark ? addressObj.landmark + ", " : ""}` +
    `${addressObj?.city ? addressObj.city + ", " : ""}` +
    `${addressObj?.state ? addressObj.state + ", " : ""}` +
    `${addressObj?.pinCode ? addressObj.pinCode + ", " : ""}` +
    `${addressObj?.country ? addressObj.country : ""}`
  );
}

export function getShippingValuesString(addressObj, email, name, shippingCarrier) {
  let shippingString = "";

  shippingString += `${addressObj?.houseNo ? addressObj.houseNo + ", " : ""}`;
  shippingString += `${addressObj?.area ? addressObj.area + ", " : ""}`;
  shippingString += `${addressObj?.landmark ? addressObj.landmark + ", " : ""}`;
  shippingString += `${addressObj?.city ? addressObj.city + ", " : ""}`;
  shippingString += `${addressObj?.state ? addressObj.state + ", " : ""}`;
  shippingString += `${addressObj?.pinCode ? addressObj.pinCode + ", " : ""}`;
  shippingString += `${addressObj?.country ? addressObj.country + ", " : ""}`;
  shippingString += `${name ? name + ", " : ""}`;
  shippingString += `${addressObj?.mobileNumber ? addressObj.mobileNumber + ", " : ""}`;
  shippingString += `${email ? email + ", " : ""}`;
  shippingString += `${shippingCarrier ? shippingCarrier : ""}`;

  return shippingString;
}
