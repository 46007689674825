export const GET_ALL_LANDING_PAGE_DATA = "get_all_landing_page_data";
export const GET_ALL_LANDING_PAGE_DATA_SUCCESS = "get_all_landing_page_data_success";
export const GET_NFT_DATA = "GET_NFT_DATA";
export const GET_NFT_DATA_SUCCESS = "GET_NFT_DATA_SUCCESS";
export const BMW = "BMW";
export const BMW_SUCCESS = "BMW_SUCCESS";
export const COMINGSOON_MARKETPLACE = "comingsoon_market_place";
export const COMINGSOON_MARKETPLACE_SUCCESS = "comingsoon_market_place_success";

export const GET_NFT_TAX = "get_nft_tax";
export const GET_NFT_TAX_SUCCESS = "get_nft_tax_success";
