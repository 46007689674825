import axios from "../../utils/axios";
import { all, fork, put, takeLatest, select } from "redux-saga/effects";
import { GET_ALL_LANDING_PAGE_DATA, GET_NFT_DATA, BMW, COMINGSOON_MARKETPLACE, GET_NFT_TAX } from "./constants";
import {
  getAllLandingPageDataSuccess,
  getnftDataSuccess,
  bmwPageSuccess,
  comingSoonMarketplaceSuccess,
  getNftTaxSuccess
} from "./actions";

import { sagaErrorHandler } from "../../shared/helperMethods/sagaErrorHandler";
import { setNotification } from "../../shared/helperMethods/setNotification";
import { makeSelectAuthToken, makeSelectAuthId } from "store/Selector";
import { updateSingleUri } from "utils/updateUriSingle";

function* getAllLandingPageDataRequest({ payload }) {
  try {
    const response = yield axios.get(
      `/marketplace${payload?.gPage ? `?gPage=${payload.gPage}&nPage=${payload.nPage}&size=${payload.size}` : ""}&isShowAll=${payload.isShowAll == "isShowAll" ? true : false}`
    );
    yield put(getAllLandingPageDataSuccess(response.data.data));
  } catch (error) {
    yield sagaErrorHandler(error.response.data.data);
  }
}

export function* watchGetAllLandingPageData() {
  yield takeLatest(GET_ALL_LANDING_PAGE_DATA, getAllLandingPageDataRequest);
}
function* getNftDataRequest({ payload }) {
  try {
    const token = yield select(makeSelectAuthToken());
    let headers = {};
    let cartHeaders = {};
    let cartResponse = null;
    let wishlistResponse = null;
    let UserId = null;
    if (token) {
      headers = { headers: { Authorization: token } };
      cartHeaders = { headers: { Authorization: `Bearer ${token}` } };
      cartResponse = yield axios.get(`/users/cart/cart-item-already-exist/${payload.id}`, cartHeaders);
      wishlistResponse = yield axios.get(`/users/wishlist/wishlist-item-already-exist/${payload.id}`, cartHeaders);
      UserId = yield select(makeSelectAuthId());
    }

    const response = yield axios.get(
      `/nft/${payload.id}?${payload?.userAddressId ? `userAddressId=${payload.userAddressId}` : ""}${
        payload?.quantity ? `&quantity=${payload.quantity}` : ""
      }`,
      headers
    );
    const filteredRes = response?.data?.data?.nft;
    if (UserId) {
      if (filteredRes?.progressState === "updateUriPending" && filteredRes?.updateUriPendingBy === +UserId) {
        // This section is for single nft where bulkId is null
        if (!filteredRes?.bulkId && !filteredRes?.updateUriPendingForEdit && !filteredRes?.updUriPendRedeemEdit) {
          const { contractAddress, id } = filteredRes;
          const tokenId = filteredRes?.NFTTokens[0]?.tokenId;
          const tokenPId = filteredRes?.NFTTokens[0]?.id;
          const data = { contractAddress, nftId: id, tokenId, tokenPId };
          updateSingleUri(data, token);
        }
        if (filteredRes?.updateUriPendingForEdit && !filteredRes?.updUriPendRedeemEdit) {
          const { contractAddress, id } = filteredRes;
          const updateUriReason = "updateUriForEdit";
          const tokenId = filteredRes?.NFTTokens[0]?.tokenId;
          const tokenPId = filteredRes?.NFTTokens[0]?.id;
          const data = { contractAddress, nftId: id, tokenId, updateUriReason, tokenPId };
          updateSingleUri(data, token);
        }
        if (filteredRes?.updUriPendRedeemEdit) {
          const { contractAddress, id } = filteredRes;
          const updateUriReason = "updateUriForRedeemedNft";
          const tokenId = filteredRes?.NFTTokens[0]?.tokenId;
          const tokenPId = filteredRes?.NFTTokens[0]?.id;
          const data = { contractAddress, nftId: id, tokenId, updateUriReason, tokenPId };
          updateSingleUri(data, token);
        }
      }
    }
    // yield put(getnftDataSuccess({ ...response?.data?.data, existsInCart: cartResponse?.data?.data }));
    yield put(
      getnftDataSuccess({
        ...response?.data?.data,
        existsInCart: cartResponse?.data?.data,
        existsInWishlist: wishlistResponse?.data?.data
      })
    );

    if (payload?.setFetchNftLoading) {
      payload.setFetchNftLoading(false);
    }
  } catch (error) {
    yield sagaErrorHandler(error.response.data.data);
    if (payload?.setFetchNftLoading) {
      payload.setFetchNftLoading(false);
    }
  } finally {
    if (payload?.setFetchShippingPriceLoading) {
      payload.setFetchShippingPriceLoading(false);
    }
  }
}

export function* watchGetNftData() {
  yield takeLatest(GET_NFT_DATA, getNftDataRequest);
}
function* getBMWDataRequest({ payload }) {
  try {
    const headers = { headers: { Authorization: `Bearer ${yield select(makeSelectAuthToken())}` } };
    const response = yield axios.get(
      `dynamicBrandData?BrandId=${payload.BrandId}&isShowAll=${payload.isShowAll == "isShowAll" ? true : false}`,
      headers
    );
    yield put(bmwPageSuccess(response.data.data));
  } catch (error) {
    yield sagaErrorHandler(error.response.data.data);
    console.log(error, "error===>");
  }
}

export function* watchGetbmwData() {
  yield takeLatest(BMW, getBMWDataRequest);
}

function* getNftTaxRequest({ payload }) {
  try {
    const headers = { headers: { Authorization: `Bearer ${yield select(makeSelectAuthToken())}` } };
    const response = yield axios.get(
      `nft/calculate-tax?userAddressId=${payload.userAddressId}&nftId=${payload.nftId}`,
      headers
    );
    yield put(getNftTaxSuccess({ ...response.data.data }));
  } catch (error) {
    yield sagaErrorHandler(error.response.data.data);
  }
}

export function* watchNftTax() {
  yield takeLatest(GET_NFT_TAX, getNftTaxRequest);
}

function* getComingsoonMarketPlaceRequest({ payload }) {
  const response = yield axios.get(`coming-soon-data?size=${payload.pageLimit}&page=${payload.page}`);
  yield put(comingSoonMarketplaceSuccess(response.data.data));
}
export function* watchComingsoonMarketplace() {
  yield takeLatest(COMINGSOON_MARKETPLACE, getComingsoonMarketPlaceRequest);
}
export default function* landingPageSaga() {
  yield all([
    fork(watchGetAllLandingPageData),
    fork(watchGetNftData),
    fork(watchGetbmwData),
    fork(watchComingsoonMarketplace),
    fork(watchNftTax)
  ]);
}
